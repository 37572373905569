.sensor-battery{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;   
    gap: 4px;

}

.sensor-battery-svg{

    width: 25px;

}

.sensor-battery-percentage{

    font-weight: 600;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}