.lesson-loading{

    align-self: center;
    justify-self: center;

}

.lesson-container{
       
    display: flex;
    padding: var(--spacing_md, 24px) var(--spacing_lg, 32px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing_sm, 16px);
    flex: 1 0 0;
    align-self: stretch;
}

.lesson-wrapper{

    display: flex;
    padding: var(--spacing-xl, 48px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-lg, 32px);
    background: var(--white, #FFF);   
    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    overflow: auto;
    width: 100%;
}

.lesson-button-wrapper{
    gap: 2px;
    display: flex;
    flex-direction: row;
}

.text-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
  
.activity-button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
  
.quiz-button{
    border-radius: 0;
}

.lesson-content-wrapper{

    display: flex;
    flex-direction: column;
    gap: 24px;

}


.course-content-container{

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    height: 100%;
    border-radius: var(--radius_md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    background: #535659;
    overflow: auto;
}

/* replace later - using base programming class */

.blockly-workspace-container{

    min-height: 400px;

}

.programming-wrapper{

    padding: 0;

}