.programming-wrapper{

    padding: var(--spacing-md, 24px) var(--spacing-lg, 32px);
    gap: var(--spacing-sm, 16px);

}

.blockly-workspace-container {
    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    background: var(--semantic-blockly-workspace-background, #FFF);
    overflow: hidden;
}
  
#blockly-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
  

div#blockly-div feFlood {
    flood-color: var(--green-40);
}

.blocklyText,
.blocklyEditableText {
	font: 700 16px Schibsted Grotesk,Arial,Helvetica,sans-serif;
    fill: var(--white);
}

.blocklyEditableText .blocklyText:not(.blocklyDropdownText) {
    fill: var(--black);
}

.blocklyDropdownRect, text.blocklyDropdownText, .blocklyEditableText image {
    cursor: pointer;
}

.button-wrapper > :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
  
.button-wrapper > :last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
  
.button-wrapper-3 > :nth-of-type(2) {
    border-radius: 0;
}

.run-tinyfarm-btn{

    flex-grow: 0;

}

/* Toolbox styling */

.blocklyToolboxDiv::before {
	content: 'Blocks';
	display: block;
    color: var(--semantic-blockly-toolbox-foreground, #655DDA);
    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    letter-spacing: -0.72px;
    display: flex;
    padding: var(--spacing-md, 24px);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.blocklyToolboxDiv {
    border-right: 2px solid var(--purple-20, #E5E5FF);
    background: var(--semantic-blockly-toolbox-background, #FFF);
}

.blocklyToolboxContents{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

}

.blocklyToolboxCategory {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    overflow: hidden;
}

.custom-base-container{ border-left: 8px solid var(--purple-50);}
.custom-logic-container{ border-left: 8px solid #CC9E43;}
.custom-loops-container{ border-left: 8px solid #CF5DCA;}
.custom-farm-container{ border-left: 8px solid var(--green-50);}

.blocklyTreeRow{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    min-height: 40px;
    margin-bottom: 0 !important;

}

.blocklyTreeRowContentContainer{

    display: flex;
    height: 40px;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px) var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    align-items: center;
    gap: var(--spacing-2-xs, 4px);
    align-self: stretch;

}

.blocklyTreeIcon {
    display: none !important;
}

.blocklyTreeLabel {
    color: var(--semantic-blockly-toolbox-foreground, #655DDA);
    font-family: Schibsted Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.16px;
}
.blocklyTreeSelected {
/* have to run with importants to override some base blocky styles */
    background-color: var(--purple-10, #F0F0FF) !important;
}

.blocklyTreeSelected .blocklyTreeLabel {

    color: var(--semantic-blockly-toolbox-foreground, #655DDA) !important;

}

.set-color-light-block path {
    stroke: var(--semantic-blockly-toolbox-foreground, #009454);
}

.color-selection-box-start {
    pointer-events: none;
    transform: translateX(10px);
}

.color-selection-box-end {
    pointer-events: none;
    transform: translateX(-8px);
}

/* flyout */


.blocklyFlyoutScrollbar{

    display: none;

}

.blocklyFlyoutBackground {

    fill: var(--purple-10, #F0F0FF);

}

.blocklyFlyoutLabelText{

    color: #746CE5;

}

/* scrollbars */


@keyframes fadeout {
    from { opacity: 0.7; }
    to { opacity: 0; }
}


.blocklyMainWorkspaceScrollbar{

    visibility: hidden;

}

.blocklyMainWorkspaceScrollbar .blocklyScrollbarHandle {

    fill: #746CE5;
    opacity: 0.7;

}


/* Modal styling */

.program-input-name{
    
    color: var(--purple-70, #655DDA);
    /* body/small/bold */
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;

}
.program-save-input{
    display: flex;
    height: 40px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid var(--purple-40, #9A93FB);
    background: var(--white, #FFF);
    min-width: 500px;
    padding-left: 10px;
    font-size: 14px;
}

.modal-codes-wrapper{

    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-20, #E5E5FF);
    background: var(--white, #FFF);
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap; 
    align-items: flex-start;
    flex: 1 0 0;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto; 
}

.open-modal-bottom{

    flex: 1 0 0;
    align-self: stretch;

}