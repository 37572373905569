.quiz h3 {
    margin-top: 24px;
}

.activity-content .programming-wrapper {
    padding: 0;
    border: 2px solid var(--purple-20);
    border-radius: 16px;
    overflow: hidden;
    gap: 0;
}

.activity-content .programming-wrapper .action-bar {
    padding: 16px;
}

.activity-content .programming-wrapper .blockly-workspace-container {
    border-radius: 0;
    border: none;
    border-top: 2px solid var(--purple-20);
}

.activity-content .question {
    margin-bottom: 48px;
    gap: 16px;
}

.question-container{
    display: flex;
    padding: var(--spacing_sm, 16px) 0 var(--spacing_xs, 8px) 0;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.question-title {
    margin-bottom: 16px;
}

.question-item{
    display: flex;
    align-items: center;
    gap: var(--spacing_xs, 8px);
    margin-bottom: 8px;
}

.question-value{
    display: flex;
    gap: var(--spacing_xs, 8px);
    cursor: pointer;
}

.question-checkbox {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--purple-40);
    border-radius: 1000px;
    background: var(--white);
}

.question-checkbox.correct {
    border: 2px solid var(--green-40);
    background: var(--green-40);
}

.question-checkbox.incorrect {
    border: 2px solid var(--red-40);
    background: var(--red-40);
}

.question-checkbox svg {
    width: 16px;
    fill: var(--white)
}