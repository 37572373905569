.dropdown {
    display: flex;
    /* padding: 0px; */
    /* flex-direction: column; 
    align-items: flex-start; */
    position: relative; 
    cursor: pointer;
    background: var(--white, #FFF);
    border: 2px solid var(--purple-20, #E5E5FF);
    border-radius: var(--spacing-xs, 8px);
    /* height: 44px; */
}

.dropdown-menu {
    max-height: 180px;
    min-width: calc(100% + 4px);
    flex-direction: column;
    /* align-items: flex-start; */
    background: var(--white, #FFF);
    border: 2px solid var(--purple-20, #E5E5FF);
    border-radius: var(--spacing-xs, 8px);
    display: none;
    /* overflow: auto; Uncomment this if you want to allow scrolling */
    transition: 0.3s ease-in-out; 
    position: absolute;
    top: 100%;
    /* left: 0; */
    z-index: 100;
    margin-left: -2px;
    overflow: auto;
    padding: 8px 0;
    cursor: default;
}

.dropdown-menu::-webkit-scrollbar {
    display: none;
}

.dropdown-menu.open {
    display: inline-flex;
}

.dropdown-item{

    display: flex;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    align-items: center;
    gap: var(--spacing-xs, 8px);
    /* justify-content: flex-start;
    width: 100%; */
    cursor: pointer;

}

/* .dropdown-item-selected{
    min-height: 40px;
} */

#dropdown-colors .dropdown-menu:not(.dropdown-item-selected){

    min-width: min-content;

}

#dropdown-colors .dropdown-item-selected .dropdown-label {
    display: none;
}

.small-selected{

    min-width: auto;

}

.dropdown-svg{

    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.item-no-icon .dropdown-item:not(.dropdown-item-selected) .dropdown-svg {
    display: none;
}

.dropdown-svg svg{

    max-height: 20px;

}

.dropdown-chevron-icon svg{

    width: 12px;
    height: 12px;

}


.dropdown-label{

    color: var(--black, #1E322B);
    font-size: 14px;
    /* font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; */
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    height: 20px;
}

.dropdown-item-selected .dropdown-label {
    font-weight: 700;
    color: var(--purple-70, #655DDA);
}

.dropdown-item:not(.dropdown-item-selected):hover{

    background: var(--purple-10, #F0F0FF);

}

/* Number input */

.number-input, .static-input{

    display: flex;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    align-items: center;
    /* background: var(--white, #FFF); */
    justify-content: space-around;
    height: 100%;
    gap: 4px;

}

.number-input-field, .number-input-unit, .static-input-field{

    color: var(--purple-70, #655DDA);
    flex-shrink: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.16px;
    border: none;
    outline: none;
    background: none;
    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: textfield;

}

.number-input-field{

    max-width: 30px;
    text-align: center;

}

.number-input-field::-webkit-outer-spin-button,
.number-input-field::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

.number-input-unit {
    display: flex;
    padding: 0px var(--spacing_2xs, 4px);
    align-items: center;
    border-radius: var(--radius_xs, 4px);
    background: var(--purple-10, #F0F0FF);
}


.combined-selector{

    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border: 2px solid var(--purple-20, #E5E5FF);
    border-radius: 8px;
    height: 40px;
    /* box-sizing: border-box; */
    
}

.combined-selector > *:not(:last-child):not(:only-child) {
    border-right: 2px solid var(--purple-20, #E5E5FF);
}

.selector-item{
    height: 100%;
    /* box-sizing: border-box; */
}

.selector-item .dropdown {
    border: none;
    /* height: initial; */
}



/* .number-input{

    height: 40px;
    box-sizing: border-box;
    border-radius: var(--spacing-xs, 8px);
    align-items: center;
    justify-content: flex-start;

} */


.btn-pump{

    flex-grow: 0;
    justify-self: flex-end;

}