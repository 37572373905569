/* In the modal */

.modal .program-running-preview{

    display: flex;
    padding: var(--spacing-md, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--green-40, #18D868);
    background: var(--green-10, #DEFFE1);
    overflow-y: scroll;

}

.modal .program-running-preview * {
  cursor: default !important;
}