.controls{

    gap: var(--spacing-xl, 48px);
    padding: var(--spacing-xl, 48px);

}

.conditions-wrapper{

    gap: var(--spacing-2-xl, 64px);
    width: 100%;

}

.analytics{

    gap: var(--spacing-2-xl, 64px);


}

.sensors-wrapper{

    gap: 6px;
    z-index: 1;

}

.sensors {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.controls-wrapper{

    gap: var(--spacing-md, 24px);
    width: 100%;
    z-index: 1;

}

.controls-controls{

    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    background: var(--white, #FFF);
    overflow: auto;
    min-width: 465px;
}

.info-svg{

    cursor: pointer;
    
}

.control-element:last-of-type .dropdown-menu {
    top: auto;
    bottom: 100%;
}

/* tinyfarm frame */

.tinyfarm {
    width: 500px;
    position: relative;
}
.tinyfarm::before {
    content: "";
    float: left;
    width: 1px;
    margin-left: -1px;
    padding-top: 100%;
}
.tinyfarm::after {
    content: "";
    display: table;
    clear: both;
}
.tinyfarm svg {
    width: 214%;
    height: 214%;
    left: -13.8%;
    position: absolute;
    bottom: -28.3%;
    color: red;
}
.tinyfarm svg #color_lights {
    visibility: visible;
}
.tinyfarm svg #white_lights {
    visibility: hidden;
}

/* Light */

.boxed-icon-lights .boxed-icon {
    border: 1px solid var(--purple-20);
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%);
}