.image-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100vw;
    height: 100vh;
    background: var(--black);
}

.image-modal-content {
    position: relative;
    display: flex;
    padding: 0px var(--spacing_lg, 32px) var(--spacing_md, 24px) var(--spacing_lg, 32px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing_md, 24px);
    flex: 1 0 0;
    align-self: stretch;
    overflow: auto;  
}

.image-modal-content img{

    width: auto;
    max-height: 75%;
    flex: 1 0 0;
    object-fit: cover;

}

.image-modal-navbar{
    display: flex;
    padding: var(--spacing_md, 24px);
    justify-content: space-between;
    align-items: center;
}


.button-group-wrapper{

    display: flex;
    height: var(--size-48, 48px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.image-navbar-left{
    display: flex;
    align-items: center;
    gap: var(--spacing_sm, 16px);
}

.navbar-arrow-left{

    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: #3A403E;
    border-radius: 0;
    border-radius: var(--radius_sm, 8px);

}

.navbar-image-date{

    color: var(--white, #FFF);
    text-align: center;

    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.72px;

}

.image-navbar-right{
    
    display: flex;
    height: 40px;
    align-items: center;
    gap: var(--spacing_xs, 8px);

}