.sidebar{

    background: var(--purple-20, #E5E5FF);
    display: flex;
    width: 220px;
    padding: var(--spacing-md, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md, 24px);
    align-self: stretch;
    overflow: auto;
}

.sidebar-link{

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

}

.sidebar-button.btn {

    border-radius: var(--radius-sm, 8px);
    display: flex;
    height: 40px;
    align-items: flex-start;
}

.buttons-section{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;

}


.admin-wrapper{

    padding-top: var(--spacing-sm, 16px);
    border-top: 2px solid var(--purple-40, #9A93FB);

}

.sidebar-header{

    padding: var(--spacing-sm, 16px);
    margin-bottom: var(--spacing-md, 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;
    border-radius: var(--radius-md, 16px);
    background: var(--purple-10, #F0F0FF);

}

.sidebar-header-avatar {
    border-radius: var(--radius_sm, 8px);
    border: 2px solid var(--purple-20, #E5E5FF);
}

.sidebar-header-text{
    gap: 4px;
}

.tinyfarm-name{

    color: var(--black, #1E322B);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    letter-spacing: -0.63px;
    align-self: stretch;
    text-align: center;
    
}

.tinyfarm-id, .sidebar-header time {

    color: var(--black, #1E322B);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.14px;

}

.sidebar-header time {
    color: var(--grey-70);
    margin-top: 4px;
}

.bitmap-image{

    border-radius: 8px;
    width: 64px;
    height: 64px;

}