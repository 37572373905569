.language-selector {
    position: relative;
    width: 100%;
}

.language-selector .btn {
    width: 100%;
}

.language-selector .btn .btn-content {
    width: 100%;
    justify-content: flex-start;
}

.language-selector .btn svg {
    width: initial;
    height: initial;
}

.language-selector .dropdown-menu {
    width: 100%;
    top: auto;
    bottom: 100%;
}
/* 

.language-dropdown{
    
    border-radius: var(--radius_sm, 8px);
    border: 2px solid var(--purple-20, #E5E5FF);
    background: var(--white, #FFF);
    position: absolute;
    

}

.language-dropdown-item{

    display: flex;
    min-width: 150px;
    padding: var(--spacing_xs, 8px) var(--spacing_sm, 16px);
    align-items: center;
    gap: var(--spacing_xs, 8px);
    align-self: stretch;

}

.language-dropdown-item:hover{

    background: var(--purple-10, #F0F0FF);
    cursor: pointer;
} */