.modal {
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-blanket {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: var(--purple-90, #3D3973);
    transition: all 0.2s ease-in-out;
}

.modal-blanket.animate {
    opacity: .6;
}

.modal-container {
    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    background: var(--white, #FFF);
    box-shadow: 0px 0px 40px 0px rgba(61, 57, 115, 0.50);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md, 24px);
    transition: all .3s cubic-bezier(0.18, 0.89, 0.44, 1.16);
    z-index: 2000;
    height: auto;
    max-height: 100%;
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    position: relative;
    top: 10%;
    opacity: 0;
}

.modal-large {
    max-width: 820px;
}

.modal-container.animate {
    top: 0%;
    opacity: 1;
}

.modal-content {
    overflow: auto;
    padding: var(--spacing-xl, 48px);
    width: 100%;
}

.modal-close {
    top: 8px;
    right: 8px;
    position: absolute;
}

.modal-heading {
    margin-bottom: 16px;
}

.modal .action-buttons .btn {
    width: 100%;
}

.modal-large .action-buttons {
    max-width: 512px;
    width: 100%;
    align-self: center;
}

/* Actuator modals */

.modal-howto {
    background: var(--purple-10);
    display: flex;
    padding: var(--spacing_md, 24px);
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--radius_sm, 8px);
}

.modal-howto ul,
.modal-howto ol {
    margin: 0;
}

.modal-howto-note {
    color: var(--purple-70);
}