.programs-wrapper {
    background: var(--white);
    border: 2px solid var(--purple-20);
    height: 100%;
    overflow: auto;
    border-radius: 16px;
}

.programs {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 16px;
    height: auto;
    padding: 16px;
}

.program-code-wrapper{
    
    display: flex;
    flex: 1 0 30%; 
    max-width: calc((100% - (16px * 2))/3);
    align-self: stretch;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;

}

.histprogram-svg-icon{

    width: 100%;
    min-width: 100%;
    border-radius: var(--radius-sm, 8px);
    border: 2px solid var(--purple-20, #E5E5FF);
    background: var(--purple-10, #F0F0FF);
    position: relative;
    height: 0;
    padding-top: calc(9/16 * 100%);
    overflow: hidden;

}

.histprogram-svg-icon svg {
    max-height: 72%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 85%;
    width: 100%;
    height: 100%;
}

.active .histprogram-svg-icon {
    border: 2px solid var(--green-40, #18D868);
    background: var(--green-10, #DEFFE1);
}


.histprogram-svg-icon * {
    cursor: pointer;
}

.histprogram-name{

    color: var(--purple-70, #655DDA);

}

.active .histprogram-name{

    color: var(--green-50);

}
