.breadcrumb-container{
    display: flex;
    height: 40px;
    padding: var(--spacing-xs, 8px) var(--spacing-md, 24px);
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-xs, 8px);
    width: 100%;
    text-transform: capitalize;
    overflow: ellipsis;
    color: var(--purple-70, #655DDA);
    text-overflow: ellipsis;

    /* heading/preheader */
    font-size: 12px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: var(--radius-sm, 8px);
    background: var(--purple-20, #E5E5FF);
}