.settings-wrapper-settings{
    display: flex;
    padding: var(--spacing_md, 24px);
    align-items: center;
    gap: var(--spacing_sm, 16px);
    align-self: stretch;
}

.settings-wrapper-settings:not(:last-child){
    border-bottom: 2px solid var(--primitive-purple-20, #E5E5FF);
}


.settings-wrapper-wrapper-red > .settings-wrapper-settings:not(:last-child){
    border-bottom: 2px solid var(--primitive-red-20, #FFDBDB);

}


.settings-text{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing_2xs, 4px);
    flex: 1 0 0;

}

.settings-text-header{

    color: var(--primitive-purple-70, #5D55D8);
    font-family: Schibsted Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.16px;

}

.settings-text-header-red{
    color: var(--primitive-red-70, #CE3B3B);
}