.info-svg{

    width: 16px;
    height: 16px;

}

.sensor-frame{

    display: flex;
    align-items: center;
    gap: var(--spacing-2-xs, 4px);

}

.text-value, .text-unit{

    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    letter-spacing: -0.72px;

}
.sensor-block-idle .text-value,
.sensor-block-idle .text-unit{
    color: var(--purple-50, #746CE5);
}

@keyframes sensordata {
    0% { color: var(--green-40, #18D868); } 
    90% { color: var(--green-40, #18D868); } 
    100% { color: var(--purple-50, #746CE5); } 
  }
  
.sensor-block-active .text-value,
.sensor-block-active .text-unit {

animation: sensordata 1.1s;

}

.sensors-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 100%;
}

.sensor-block{

    border-radius: 8px;
    padding: 8px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 2.5 2.5 25%;
    max-width: 25%;
}

.sensor-block-inactive .text-value{
    color: var(--purple-40, #9A93FB);
}

.sensor-block{

    border: 1px solid var(--purple-40, #9A93FB);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--purple-20);
}

.actuator-block{

    background-color: var(--grey-10);

}

.text-value{
    white-space: nowrap;
}

.sensor-middle{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.sensor-data{

    display: flex;
    flex-direction: column; 
    align-items: center;
}

.sensor-frame{

    display: flex;
    flex-direction: row;

}

.sensor-data-values{

    display: flex;

}


.sensor-block-header{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.sensor-block-svg{

    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;

}

.sensor-battery-wrapper{

    flex: 3 3 30%;
    min-width: 30%;

}
.sensor-protocol-svg {
    position: relative; 
    display: flex; 
}


.sensor-protocol-svg svg{

    width: 24px;
    height: 24px;
    cursor: pointer;

}


.sensor-svg-options {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    padding: 10px;
    z-index: 100;
}
  
  
.sensor-svg-options-backdrop {
    position: fixed; 
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.sensor-delete-svg, .sensor-refresh-svg{

    cursor: pointer;

}

.sensor-type-span{

    font-weight: 600;
    font-size: 24px;

}

.sensor-value-span{

    font-weight: 600;
    font-size: 32px;
}

.sensor-bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    flex: 1 1 100%;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.measure-interval-text{

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple-70, #655DDA);   
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.16px;
    padding-left: 4px;
    padding-right: 4px;
}

.measure-controls{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 4px;
    padding: 4px;
    border-left: 1px solid var(--purple-40); 

}
.measure-interval-slider{

    width: 100%;

}

.dropdown-svg{

    display: none;

}

.dropdown-item{

    padding: 3px;

}

.fade-out {
    animation: fadeOut 0.5s forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }