@keyframes slideUp {
  from {
    bottom: 0px;
    opacity: 0;
  }
  to {
    bottom: 16px;
    opacity: 1;
  }
}

.toast {
  position: absolute;
  bottom: 16px;
  left: 50%; 
  transform: translateX(-50%); 
  z-index: 100;
  border-radius: var(--radius-sm, 8px);
  background: var(--purple-70);
  display: inline-flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 8px;
  padding: var(--spacing_2xs, 4px);
  animation: slideUp .3s cubic-bezier(0.18, 0.89, 0.44, 1.16);
  margin-left: 86px;
}

.toast.page-full {
  margin-left: 0;
}

.toast-left {
    display: flex;
    padding: 0px var(--spacing-sm, 8px);
    align-items: center;
    justify-content: center;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;
}

.toast-message-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-sm, 8px);
  padding: 0px var(--spacing_xs, 8px);
}

.toast-svg-icon {
    width: 50px; 
    height: auto; 
    display: block;
}

.toast-label {
  color: var(--white);
}

.toast-right {

    display: flex;
    padding: 0px var(--spacing-sm, 16px);
    align-items: center;
    gap: var(--spacing-sm, 16px);
    align-self: stretch;
}

.toast-purple {
  background: var(--purple-70);
}

.toast-green {
  background: var(--green-70);
}

.toast-red{
  background: var(--red-70, #CE3B3B);
}

.toast-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  padding: var(--spacing_2xs, 4px);
  border-radius: var(--radius_xs, 4px);
  background: var(--white);
}

.toast-preview * {
  cursor: default !important;  
}

.histprogram-svg-icon{

  width: 100%;
  min-width: 100%;
  border-radius: var(--radius-sm, 8px);
  border: 2px solid var(--purple-20, #E5E5FF);
  background: var(--purple-10, #F0F0FF);
  position: relative;
  height: 0;
  padding-top: calc(9/16 * 100%);
  overflow: hidden;

}

.histprogram-svg-icon svg {
  max-height: 72%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  width: 100%;
  height: 100%;
}