.control-header-text{


    color: var(--purple-70, #655DDA);
    font-family: Space Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    letter-spacing: -1.28px;

}