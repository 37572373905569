.btn{
    
    display: inline-flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: var(--radius-sm, 8px);
    cursor: pointer;
}

.btn-content{

    display: flex;
    padding: 0 var(--spacing-sm, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    flex: 1 0 0;

}

.btn-primary .btn-label{

    color: var(--white, #FFF);

}

.btn svg{

    width: 20px;
    height: 20px;

}

.btn-primary svg path{

    fill: var(--white, #FFF);

}

.btn-green{

    background: var(--green-50, #00B868);

}

.btn-purple{

    background: var(--purple-50, #746CE5);

}

.btn-red{

    background: var(--red-50, #E15656);

}

.btn-dark{

    background: rgba(255, 255, 255, 0.10);

}

.btn-secondary.btn-green {
    
    background: var(--green-20, #C7FACC);


}

.btn-secondary.btn-green svg path{
    
    fill: var(--green-70, #00B868);


}
  
.btn-secondary.btn-green .btn-label{
    
    color: var(--green-70, #00B868);

}



.btn-secondary.btn-purple {
    
    background: var(--purple-20, #E5E5FF);


}

.btn-secondary.btn-purple svg path{
    
    fill: var(--purple-70, #655DDA);


}
  
.btn-secondary.btn-purple .btn-label{
    
    color: var(--purple-70, #655DDA);

}

.btn-pumping{

    background:  var(--white, #fff);
    border: 2px solid var(--green-40, #18D868);

}

.btn-pumping .btn-label{

    color: var(--green-70, #009454);


}

.btn-label{

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.btn-pumping {
    position: relative;
    overflow: hidden;
  }
  
  .btn-pumping::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transform: translateX(-100%);
    background-color: #C7FACC;
    z-index: -1;
  }
  
  @keyframes fill {
    to {
      transform: translateX(0);
    }
  }

.icon-btn {
    width: 40px;
}

.button-group {
    display: flex;
    align-items: center;
    gap: 2px;
}

.button-group button {
    border-radius: 0;
}

.button-group > button:first-of-type,
.button-group a:first-of-type button {
    border-top-left-radius: var(--radius_sm, 8px);
    border-bottom-left-radius: var(--radius_sm, 8px);
}

.button-group > button:last-of-type,
.button-group a:last-of-type button {
    border-top-right-radius: var(--radius_sm, 8px);
    border-bottom-right-radius: var(--radius_sm, 8px);
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: var(--spacing_sm, 8px);
}

/* Small button */

.btn-small {
    height: 32px;
    border-radius: var(--radius_xs, 4px);
}

.btn-small .btn-content {
    padding: 0 var(--spacing-sm, 8px);
    gap: var(--spacing-xs, 4px);
}

.btn-small .btn-icon-first {
    width: 16px;
    height: 16px;
    font-size: 16px;
}

.button-group > .btn-small:first-of-type,
.button-group a:first-of-type .btn-small {
    border-top-left-radius: var(--radius_xs, 4px);
    border-bottom-left-radius: var(--radius_xs, 4px);
}

.button-group > .btn-small:last-of-type,
.button-group a:last-of-type .btn-small {
    border-top-right-radius: var(--radius_xs, 4px);
    border-bottom-right-radius: var(--radius_xs, 4px);
}