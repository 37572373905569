@tailwind base;
@tailwind components;
@tailwind utilities;


/* Space Grotesk font */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');

/* Schibsted Grotesk font */
@import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* Variables - Color */

:root {

  /* Base */
  --white: #ffffff;
  --grey-10: #ECEEED;
  --grey-20: #DCDFDE;
  --grey-40: #A1AAA7;
  --grey-50: #8C9793;
  --grey-70: #66706D;
  --grey-90: #3A403E;
  --black: #1E322B;

  /* Green */
  --green-10: #DEFFE1;
  --green-20: #C7FACC;
  --green-40: #18D868;
  --green-50: #00B868;
  --green-70: #009454;
  --green-90: #005229;

  /* Purple */
  --purple-10:#F0F0FF;
  --purple-20: #E5E5FF;
  --purple-40: #9A93FB;
  --purple-50: #7870E6;
  --purple-70: #5D55D8;
  --purple-90: #3D3973;

  /* Red */
  --red-10: #FFEBEB;
  --red-20: #FFDBDB;
  --red-40: #FF7070;
  --red-50: #E15656;
  --red-70: #CE3B3B;
  --red-90: #6F2525;
}

/* Base */

* {
  font-family: "Schibsted Grotesk", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.01em;
}

#root {
  overflow: hidden;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

/* Required in order to have custom sliders and thumbs */
input[type=range] {
  -webkit-appearance: none; 
  appearance: none;
}

/* 
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
} */


/* Text styles */

p, ul, ol {
  margin: 0 0 12px;
}

p.small, .small, .small * {
  font-size: 14px;
}

p.xsmall, .xsmall, .xsmall * {
  font-size: 12px;
}

ul li {
  margin: 0px;
}

ol {
  list-style: auto;
}

ol li {
  margin-left: 26px;
  margin-bottom: 8px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: 'Space Grotesk', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: var(--purple-70);
}

h1, .h1, h2, .h2 {
  font-size: 40px;
  line-height: 92%;
  letter-spacing: -0.06em;
  margin: 0 0 8px;
}

h3, .h3 {
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.04em;
  margin: 0 0 24px;
}

h4, .h4 {
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.03em;
  margin: 0 0 16px;
}

h5, .h5 {
  font-style: normal;
  font-weight: 700;
  color: var(--purple-50);
  margin: 0 0 8px;
}

h6, .h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: var(--purple-50);
  margin: 8px 0 8px;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
p:last-child,
ul:last-child {
  margin-bottom: 0;
}

h6:first-child {
  margin-top: 0px;
}

sub {
  display: inline-block;
  font-family: inherit;
  font-size: .6em !important;
  font-weight: inherit;
  line-height: 0;
  color: inherit;
}

blockquote {
  border-left: 2px solid var(--green-40);
  margin: 16px 0 32px;
  padding-left: 32px;
}

blockquote h4 {
  font-family: 'Schibsted Grotesk', Arial, Helvetica, sans-serif;
  line-height: 120%;
  letter-spacing: -0.02em;
}

blockquote cite {
  font-size: 16px;
  font-style: italic;
}

sup {
  display: inline-block;
  font-family: inherit;
  font-size: .6em !important;
  font-weight: inherit;
  line-height: 0;
  color: inherit;
}

.preheader {
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0 0 8px;
  color: var(--green-50);
}

b, .bold {
  font-weight: 700;
}

/* Text color */

.text-green {
  color: var(--green-70);
}

.text-purple {
  color: var(--purple-70);
}

.text-white {
  color: var(--white);
}

/* Text align */

.text_align_left {
  text-align: left;
}

.text_align_right {
  text-align: right;
}

.text_align_center {
  text-align: center;
}

/* Layout */

.main {
  min-height: 620px;
}

.content{

  background: var(--purple-10, #F0F0FF);
  overflow: auto;

}

.app_wrapper{
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 96px 32px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.wrapper,
.wrapper_m,
.wrapper_s,
.wrapper_xs {
  max-width: 420px;
}

.dir_col {
  flex-direction: column;
}

.align_start {
  align-items: flex-start;
}

.align_center {
  align-items: center;
}

.align_self_start {
  align-self: flex-start;
}

.justify_center {
  justify-content: center;
}

.justify_stretch {
  justify-content: stretch;
}

.wrapper .column {
  flex: 1 1;
}

.wrapper .column.flex_1_3 {
  flex: 1.3 1;
}

.gap-0 {
  gap: 0;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-48 {
  gap: 48px;
}

.gap-64 {
  gap: 64px;
}

.gap-96 {
  gap: 96px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

/* Show/Hide */

.show_desktop {
  display: none !important;
}

.hide_desktop {
  display: inherit !important;
}

/* Emoji */

.emoji-sm {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.emoji-md {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.emoji-lg {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

.emoji-xl {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
}

/* Card */

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 2.5 2.5 25%;
  padding: 24px;
  background: var(--white);
  border: 2px solid;
  border-radius: 16px;
  gap: 24px;
}

.card.align_start {
  align-items: flex-start;
}

.card.justify_start {
  justify-content: flex-start;
}

.card.bg_transparent {
  background: transparent;
}

.card .card_icon_wrapper {
  display: flex;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
}

/* Green card */

.card.green {
  border-color: var(--green-40);
}

.card.green .card_icon_wrapper {
  background-color: var(--green-20);
}

.card.green .card_icon_wrapper svg path {
  fill: var(--green-50);
}

/* Purple card */

.card.purple {
  border-color: var(--purple-50);
}

.card.purple .card_icon_wrapper {
  background-color: var(--purple-20);
}

.card.purple .card_icon_wrapper svg path {
  fill: var(--purple-50);
}


/* Loading placeholder */

.loading-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--purple-70);
}

.loading-placeholder-container {
  gap: 16px;
  background: var(--white, #FFF);
  border-radius: var(--radius_md, 16px);
  border: 2px solid var(--purple-40, #9A93FB);
}

.MuiCircularProgress-root {
  color: var(--purple-50) !important;
  width: 32px !important;
  height: 32px !important;
}


/* Action bar */

.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--spacing_sm, 16px);
}


/* Desktop styles */

@media (min-width: 768px) {
  /* Text styles */

  h1, .h1 {
    font-size: 56px;
  }
  
  h2, .h2 {
    font-size: 48px;
  }

  /* Layout */

  .container {
    padding: 96px 48px;
  }

  .wrapper {
    flex-direction: row;
    max-width: 1080px;
  }

  .wrapper_m {
    max-width: 920px;
  }
  
  .wrapper_s {
    max-width: 720px;
  }
  
  .wrapper_xs {
    max-width: 600px;
  }

  .dir_col {
    flex-direction: column;
  }
  
  .dir_row_reverse {
    flex-direction: row-reverse;
  }

  /* Show/Hide */

  .show_desktop {
    display: inherit !important;
  }

  .hide_desktop {
    display: none !important;
  }
}

