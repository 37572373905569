

.login-wrapper{

    display: flex;
    padding: var(--spacing-2-xl, 64px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xl, 48px);
    flex: 1 0 0;
    align-self: stretch;

}

.login-info-wrapper{

    display: flex;
    width: 340px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm, 16px);

}

.login-form-wrapper{

    display: flex;
    max-width: 350px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    background: var(--white, #FFF);
}

.login-form-content{

    display: flex;
    padding: var(--spacing-lg, 32px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg, 32px);
    align-self: stretch;

}

.login-dash{

    color: var(--purple-70, #5D55D8);
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.14px;
}

.login-form-top, .login-form-bottom{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;

}

.login-label{

    align-self: stretch;
    color: var(--purple-70, #5D55D8);
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
}

.login-inputs{

    display: flex;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    align-self: stretch;

}

.login-name-input{

    height: 40px;
    border-radius: var(--radius-sm, 8px);
    border: 2px solid var(--purple-20, #E5E5FF);
    background: var(--white, #FFF);
    max-width: 45%;
    display: flex;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    align-items: center;
    gap: var(--spacing-xs, 8px);
    flex: 1 0 0;
    color: var(--black, #1E322B);
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.14px;
}

.login-name-input::placeholder{
    opacity: 0.6;
}

.login-input{

    border-radius: var(--radius-sm, 8px);
    border: 2px solid var(--purple-20, #E5E5FF);
    background: var(--white, #FFF);
    display: flex;
    width: 64px;
    height: 64px;
    padding: var(--spacing-xs, 8px) var(--spacing-sm, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xs, 8px);
    text-align: center;
}

.login-submit{

    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--radius-sm, 8px);
    background: var(--green-50, #00B868);
    color: var(--white, #FFF);
    text-align: center;
    /* body/small/bold */
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
    
}