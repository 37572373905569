.course-block-wrapper{
    display: flex;
    max-width: 720px;
    align-items: flex-start;
    gap: 10px;
    padding: var(--spacing-sm, 16px);
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    background: var(--white, #FFF);
    cursor: pointer;
}

.course-block-green{

    border-radius: var(--radius-md, 16px);
    background: var(--green-10, #DEFFE1);

}

.course-block-purple{

    border-radius: var(--radius-md, 16px);
    background: var(--purple-20, #E5E5FF);

}

.course-block-grey{

    border-radius: var(--radius-md, 16px);
    background: var(--purple-10, #F0F0FF);

}

.course-block-content{

    display: flex;
    padding: var(--spacing-xs, 8px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-xs, 8px);
    flex: 1 0 0;
    border-radius: var(--size-0, 0px);

}

.course-block-preheader{

    color: var(--green-50, #00B868);
    font-family: Schibsted Grotesk;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
    align-self: stretch;

}

.course-block-heading{

    color: var(--purple-70, #655DDA);
    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    letter-spacing: -0.72px;
    align-self: stretch;

}

.course-block-description{

    color: var(--black, #1E322B);
    font-family: Schibsted Grotesk;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
    letter-spacing: -0.14px;
    align-self: stretch;

}

.course-block-chevron{
    
    width: 40px;
    height: 40px;

}