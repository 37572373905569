
.settings-container{

    display: flex;
    padding: var(--spacing_lg, 32px) var(--spacing_xl, 48px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing_sm, 16px);
    flex: 1 0 0;
    align-self: stretch;

}

.settings-wrapper{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;

}


.settings-content{

    display: flex;
    max-width: 720px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing_xl, 48px);

}


.settings-section-heading{
    display: flex;
    align-items: center;
    gap: var(--size-8, 8px);
    color: var(--primitive-purple-70, #5D55D8);
    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    letter-spacing: -0.72px;
}
.settings-section-red{

    color: var(--primitive-red-70, #CE3B3B);

}

.settings-section{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing_sm, 16px);
    align-self: stretch;
    overflow: hidden;
}


.settings-wrapper-wrapper{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--radius_md, 16px);
    border: 2px solid var(--primitive-purple-40, #9A93FB);
    background: var(--primitive-white, #FFF);

}

.settings-wrapper-wrapper-red{
    border: 2px solid var(--primitive-red-70, #CE3B3B);
}