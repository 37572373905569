.timelapse-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100vw;
    height: 100vh;
    background: var(--black);
    z-index: 999;
}

.timelapse-modal-content {
    position: relative;
    display: flex;
    padding: 0px var(--spacing_lg, 32px) var(--spacing_md, 24px) var(--spacing_lg, 32px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing_md, 24px);
    flex: 1 0 0;
    align-self: stretch;
    overflow: hidden;  
}

.timelapse-modal-content img{

    width: auto;
    max-height: 75%;
    flex: 1 0 0;
    object-fit: cover;
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 

}

.timelapse-modal-navbar{
    display: flex;
    padding: var(--spacing_md, 24px);
    justify-content: space-between;
    align-items: center;
}


.button-group-wrapper{

    display: flex;
    height: var(--size-48, 48px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.timelapse-modal .button-group{

    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing_md, 24px);
    align-self: stretch;
    
}

.navbar-left{
    display: flex;
    align-items: center;
    gap: var(--spacing_sm, 16px);
}

.navbar-arrow-left{

    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: #3A403E;
    border-radius: 0;
    border-radius: var(--radius_sm, 8px);

}

.navbar-image-date{

    color: var(--white, #FFF);
    text-align: center;

    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.72px;

}

.timelapse-navbar-right{
    
    display: flex;
    height: 40px;
    align-items: center;
    gap: var(--spacing_xs, 8px);

}

/* progress bar */

.timelapse-button{
    cursor: pointer;
}

.progress-bar {
    display: flex;
    width: 100%;
    height: var(--Size-8, 8px);
    border-radius: var(--radius_sm, 8px);
    background: rgba(255, 255, 255, 0.30);
    position: relative;
    cursor: pointer;
}

.progress-segment {
    flex: 1;
    transition: left 0.3s ease;
}

.progress-segment:not(:nth-last-child(-n + 2)) {
    border-right: 2px solid #1E322B;
}

.progress-segment.filled:first-child{

    border-radius: 8px 0 0 8px;

}
.progress-segment.filled:nth-last-child(-n + 2){

    border-radius: 0 8px 8px 0;

}
.progress-segment.filled {
    background: var(--primitive-green-50, #00B868);
}

.progress-handle {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: left 0.3s ease; 
    
}