.gallery-wrapper{

    display: flex;
    padding: var(--spacing_md, 24px) var(--spacing_lg, 32px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing_sm, 16px);
    flex: 1 0 0;
    align-self: stretch;

}

/* .gallery-dropdown{
    display: flex;
    align-items: center;
    gap: var(--spacing_xs, 8px);
    align-self: stretch;
} */


.gallery-period{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing_sm, 16px);
    align-self: stretch;

}

.date-period-sections-wrapper{

    display: flex;
    flex: 1 1 0;
    padding: var(--spacing_lg, 32px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing_xl, 48px);
    align-self: stretch;
    background: var(--white, #FFF);
    border-radius: var(--radius_md, 16px);
    border: 2px solid var(--purple-40, #9A93FB);
    overflow: auto;
}


/* styling the date in the gallery view  */

.date-period-section{ 

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing_sm, 16px);
    flex: 1 0 0;

}

.date-period-header {
    gap: var(--spacing_2xs, 4px);
}

.date-period-title{
    gap: 8px;
}

.date-period-title svg,
.date-period-title h3 {
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    cursor: pointer;

} 

.date-period-date{

    display: flex;
    flex-direction: row;
    gap: 4px;
    color: var(--purple-70, #5D55D8);

    font-family: Schibsted Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.16px;
}

.date-period-start, .date-period-end{

    color: var(--purple-70, #5D55D8);
    align-self: stretch;
    font-family: Schibsted Grotesk;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.16px;

}

.gallery-image-wrapper{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 16px;
}

.images-container{

    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 4px var(--spacing_2xs, 4px);
    align-self: stretch;
    flex-wrap: wrap;

}

.image-selection-wrapper {
    position: relative; 
    display: inline-block; 
}

.image-selection-wrapper .image-checkbox{

    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
}


.image-selection-wrapper .image-checkbox svg{

    cursor: pointer;
}


.gallery-image {
    display: flex;
    height: 180px;
    width: 180px;
    max-width: 180px;
    object-fit: cover;
    object-position: center;
    align-items: flex-start;
    flex: 1 0 0;
    background: var(--purple-20, #E5E5FF);
    transition: padding 100ms ease-out;
    cursor: pointer;
}

.gallery-image.selected {
    padding: var(--spacing_sm, 16px);
    transition: padding 100ms ease-out;
}

.gallery-btn-wrapper{

    display: flex;
    align-items: center;
    gap: var(--spacing_xs, 8px);

}

.gallery-btn{

    max-width: 200px;

}

.gallery-image-date{

    color: var(--purple-70, #655DDA);

    font-family: Space Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
    letter-spacing: -0.72px;

}

.gallery-image-daterange{

    color: var(--purple-70, #655DDA);
    font-family: Space Grotesk;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 92%; /* 44.16px */
    letter-spacing: -2.88px;
    margin-top: 32px;

}